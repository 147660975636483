import http from '@/services'
import * as toast from '@/services/toast'
import matrixModule from '@/store/matrix'
import { Component, Vue } from 'vue-property-decorator'
import CorrelationComponent from '@/components/Correlation/CorrelationComponent.vue'
import { Matrix, Correlation, QuadrantsRelationship } from '@/Models'
import html2canvas from 'html2canvas'
import { httpFile } from '@/services/http'
import BreadCrumb from '@/components/BreadCrumb/BreadCrumb.vue'
import SubNav from '@/components/Nav/SubNav.vue'
import menuModule from '@/store/menu'
import ModalComponent from '@/components/ModalComponent/ModalComponent.vue'
import DescriptionCard from '@/components/DescriptionCard/index.vue'
import ModuleCMS from '@/store/moduleCMS'
import moduleArticle from '@/store/moduleArticle'
import router from '@/router'
//import BreadCrumbModule from '@/store/moduleBreadCrumb'
import ModuleArticle from '@/store/moduleArticle'

@Component({
  components: {
    CorrelationComponent,
    BreadCrumb,
    SubNav,
    ModalComponent,
    DescriptionCard,
  },
})
export default class ShowMatrix extends Vue {
  matrix: Matrix | null = null
  correlations: Correlation[] = []
  hh = ''
  ww = ''
  square = ''
  rows_count = 1
  columns_count = 1
  quadrant_north = 1
  quadrant_east = 1
  quadrant_south = 1
  quadrant_west = 1
  quadrant_resource = 1
  action = 'showMatrix'
  quadrant_id = ModuleArticle.quadrant_content_id
  dataRelationships: Array<QuadrantsRelationship> = []

  menu: Array<any> = [
    // {
    //   label: 'TESTDASH',
    //   description: 'Sistema para la gestión de pruebas',
    //   img: 'http://192.168.3.250:9001/img/testBot.2b02c9d5.svg',
    //   route: 'http://192.168.3.250:9001/',
    // },
    {
      label: 'Gestionar contenido asociado',
      img: 'CosMoS.svg',
      route: `${JSON.stringify({
        name: 'CMS',
        params: { matrix_id: this.matrix?.id.toString() },
      })}`,
    },
    {
      label: 'Métricas',
      img: 'Metricas.svg',
      route: `${JSON.stringify('/mci')}`,
    },
    {
      label: 'TestDash',
      img: 'TestDash.svg',
    },
    {
      label: 'Relaciones entre metricas',
      img: 'Relaciones.svg',
      route: `${JSON.stringify('/matrixRelationship')}`,
    },
    // {
    //   label: 'CMS',
    //   description: 'Gestor documental',
    //   icon: 'file-document-multiple-outline',
    //   route: `${JSON.stringify({
    //     name: 'CMS',
    //     params: { matrix_id: this.matrix?.id.toString() },
    //   }
    //   )}`,
    // },
  ]

  async mounted() {
    menuModule.setLoding(true)
    const matrix_temp = matrixModule.matrix
    // await this.getRelationshipData()
    if (matrix_temp === null) {
      this.$router.push('/matrices').catch(console.log)
      return
    } else await this.loading(matrix_temp.id)
    this.matrix = matrix_temp

    this.quadrant_east = this.matrix.east_quadrant.content.length
    this.quadrant_north = this.matrix.north_quadrant.content.length
    this.quadrant_south = this.matrix.south_quadrant.content.length
    this.quadrant_west = this.matrix.west_quadrant.content.length
    this.quadrant_resource = this.matrix.resources_quadrant.content.length
    this.rows_count += this.quadrant_north + this.quadrant_south
    this.columns_count +=
      this.quadrant_east + this.quadrant_west + this.quadrant_resource

    this.$nextTick(function () {
      const rotates = document.getElementsByClassName('rotate')
      let header_height = 0
      for (let index = 0; index < rotates.length; index++) {
        const element = rotates[index]
        if (element.clientWidth > header_height)
          header_height = element.clientWidth
      }

      const horizontal_text = document.getElementsByClassName('horizontal-text')
      for (let index = 0; index < horizontal_text.length; index++) {
        const element = horizontal_text[index]
        if (element.clientWidth > header_height)
          header_height = element.clientWidth
      }

      this.hh = `height: ${header_height + 25}px;`
      this.ww = `width: ${header_height + 25}px;`
      this.square = `height: ${header_height + 25}px; width: ${
        header_height + 25
      }px;`
    })
    menuModule.setLoding(false)
  }
  getImgUrl(image: string) {
    console.log(image, 'IMAGE')
    if (image) return require('@/assets/img/' + image)
  }
  showMenuSuite(index: number, cuadrant: string) {
    // BreadCrumbModule.validateSetRoute({
    //   text: cuadrant,
    //   icon: '',
    //   link: '',
    // })
    //const matrixD: any = matrixModule.matrix?.description
    //console.log(matrixD, 'MATRIX DESCRIPTION')
    const matrix: any = matrixModule.matrix
    const cuadrantContent: any = matrix[cuadrant].content[index]
    //const cuadrantDescription: any = matrix[description].content[index]
    ModuleCMS.setQuadrantId(cuadrantContent.id)
    moduleArticle.setQuadrantContentId(cuadrantContent.id)
    matrixModule.setQuadrantName(cuadrant)
    this.action = 'menu'
    console.log(cuadrantContent, 'LEROLERO')
    console.log(cuadrantContent.description, 'DESC')
    moduleArticle.setQuadrantContentId(cuadrantContent.id)

    this.getRelationshipData(cuadrantContent.id, cuadrantContent.description)
  }
  async getRelationshipData(cuadrant: string, cuadrantDescription: string) {
    console.log(cuadrant, 'QUADRANT ID')
    console.log(cuadrantDescription, 'DESCRIP RELA')
    const cuadrantD = cuadrantDescription
    try {
      const res = await http.get(`hoshin/quadrants_relationship/${cuadrant}/`)
      this.dataRelationships = res.data
      console.log(this.dataRelationships, 'DATARELATIONSHIP')
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  closeForm() {
    this.action = 'showMatrix'
  }

  toCms() {
    //matrixModule.setQuadrant(Quadrant)

    try {
      if (this.matrix)
        this.$router.push({
          name: 'CMS',
          params: { matrix_id: this.matrix.id.toString() },
        })
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
    // console.log('cuadrante', matrixModule.quadrant)
  }
  toRoute(url: string, props: string) {
    this.$router.push(JSON.parse(url))
  }

  async loading(matrix_id: number) {
    try {
      const res = await http.get(
        `hoshin/quadrant_correlation/${matrix_id}/correlation_in_matrix/`,
      )
      this.correlations = res.data as Correlation[]
      console.log(this.correlations, 'FSFSFS')
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }

  async to_excel() {
    try {
      const res = await httpFile.get(`hoshin/matrix_excel/${this.matrix?.id}/`)
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.matrix?.name}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }

  correlationStateByIndex(
    quadrant_content_principal_id: number,
    quadrant_content_right_id: number,
  ): Correlation | null {
    const correlation = this.correlations.find(
      (c: Correlation) =>
        c.quadrant_content_principal.id === quadrant_content_principal_id &&
        c.quadrant_content_right.id === quadrant_content_right_id,
    )
    if (correlation) return correlation
    return null
  }
  // Screen capture function
  async to_imagen() {
    const el = this.$refs.printMe
    console.log(el)
    if (el) {
      const canvas = await html2canvas(el as any, {
        logging: true,
        allowTaint: false,
        useCORS: true,
        scale: (1920 * 2) / window.innerWidth,
        backgroundColor: null,
      })
      const downloadLink = document.createElement('a')
      const fileName = `${this.matrix?.name}.jpg`
      console.log(canvas.toDataURL('image/jpg'))
      downloadLink.href = canvas.toDataURL('image/jpg')
      downloadLink.download = fileName
      downloadLink.click()
    }
  }
}
